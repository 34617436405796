import { memo } from "react"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import TitleGroup from "../TitleGroup"

const StyledEditorRow = styled.div`
  flex: 1;
  display: grid;
  align-items: center;
  ${({ displayAsColumns }) => {
    return displayAsColumns
      ? `
        grid-template-rows: auto 1fr;
        grid-row-gap: ${sizer(2)};
      `
      : `
        grid-template-columns: 1fr auto;
        grid-column-gap: ${sizer(2)};
      `
  }}
  > div:first-of-type {
    height: 100%;
  }
`

export default memo(function EditorRow({ children, displayAsColumns = false, dataTestId, ...rest }) {
  return (
    <StyledEditorRow displayAsColumns={displayAsColumns} data-testid={dataTestId}>
      <TitleGroup size="sm" {...rest} />
      {children}
    </StyledEditorRow>
  )
})
